import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Online as OnlineLayout,
  ExternalMinimal as ExternalMinimalLayout,
} from "./layouts";

import {
  Dashboard as DashboardView,
  UserList as UserListView,
  OrderList as OrderListView,
  TransactionList as TransactionListView,
  PromotionList as PromotionListView,
  PromotionTypes as PromotionTypesView,
  BuyNFreePromotion as BuyNFreePromotionView,
  NewMemberPromotion as NewMemberPromotionView,
  BirthdayPromotion as BirthdayPromotionView,
  Transaction as TransactionView,
  Order as OrderView,
  CustomerList as CustomerListView,
  User as UserView,
  RestaurantList as RestaurantListView,
  Restaurant as RestaurantView,
  MyRestaurant as MyRestaurantView,
  OpenHours as OpenHoursView,
  ServiceBellTypes as ServiceBellTypesView,
  HolidaySurcharge as HolidaySurchargeView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Online as OnlineView,
  Cart as CartView,
  Checkout as CheckoutView,
  DishDetail as DishDetailView,
  OrderSuccess as OrderSuccessView,
  QrCodes as QrCodesView,
  MenusList as MenusListView,
  Menu as MenuView,
  MenuCategory as MenuCategoryView,
  CookingCategoryList as CookiesCategoryListView,
  SalesReport as SalesReportView,
  DishesReport as DishesReportView,
  PaymentTypesReport as PaymentTypesReportView,
  AdminDashboard as AdminDashboardView,
  OrderSuccessDetail as OrderSuccessDetailView,
  Bookings as BookingsView,
  BookingSessions as BookingSessionsView,
  BookingSession as BookingSessionView,
  RestaurantBooking as RestaurantBookingView,
  BookingSuccess as BookingSuccessView,
  SalesDetailsReport as SalesDetailsReportView,
  OrderTypeReport as OrderTypeReportView,
  RestaurantSettings as RestaurantSettingsView,
  VoucherList as VoucherListView,
  NewVoucher as NewVoucherView,
  QueueForm as QueueFormView,
  Subscriptions as SubscriptionsView,
  Prices as PricesView,
  // Subscribe as SubscribeView,
  SubscriptionList as SubscriptionListView,
  POSSettings as POSSettingsView,
  StaffList as StaffListView,
  Staff as StaffView,
  TimesheetList as TimesheetListView,
  StaffActivityList as StaffActivityListView,
  RestaurantGroup as RestaurantGroupView,
  BookingSettings as BookingSettingsView,
  AdyenPayment as AdyenPaymentView,
  AdyenPaymentResult as AdyenPaymentResultView,
  ResetPassword as ResetPasswordView,
  POSPrintSettings as POSPrintSettingsView,
  POSPrinterSettings as POSPrinterSettingsView,
  Timesheet as TimesheetView,
  DeviceList as DeviceListView,
  KioskList as KioskListView,
  Kiosk as KioskView,
  AdyenSettings as AdyenSettingsView,
  TerminalList as TerminalListView,
  DishesTransactionReport as DishesTransactionReportView,
  SurchargeList as SurchargeListView,
  PaymentTypeList as PaymentTypeListView,
  PaymentType as PaymentTypeView,
  POSClientList as POSClientListView,
  POSClient as POSClientView,
  OBAdyenPayment as OBAdyenPaymentView,
  OBPaymentSuccess as OBPaymentSuccessView,
  OBPaymentFailure as OBPaymentFailureView,
  Invoice as InvoiceView,
  GrantOfferList as GrantOfferListView,
  CapitalFaqs as CapitalFaqsView,
  H5WechatPay as H5WechatPayView,
  PaymentStatus as PaymentStatusView,
  DisplayClientList as DisplayClientListView,
  DisplayClient as DisplayClientView,

} from "./views";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />

      <RouteWithLayout
        component={AdminDashboardView}
        exact
        layout={MainLayout}
        path="/adminDashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />

      <RouteWithLayout
        component={StaffListView}
        exact
        layout={MainLayout}
        path="/staffList"
      />

      <RouteWithLayout
        component={StaffView}
        exact
        layout={MainLayout}
        path="/staff/:staffId?"
      />

      <RouteWithLayout
        component={KioskView}
        exact
        layout={MainLayout}
        path="/kiosk/:kioskId?"
      />

      <RouteWithLayout
        component={CustomerListView}
        exact
        layout={MainLayout}
        path="/customers"
      />

      <RouteWithLayout
        component={MenusListView}
        exact
        layout={MainLayout}
        path="/mymenus"
      />

      <RouteWithLayout
        component={SalesReportView}
        exact
        layout={MainLayout}
        path="/salesReport"
      />

      <RouteWithLayout
        component={PaymentTypesReportView}
        exact
        layout={MainLayout}
        path="/paymentTypesReport"
      />

      <RouteWithLayout
        component={DishesReportView}
        exact
        layout={MainLayout}
        path="/dishesReport"
      />

      <RouteWithLayout
        component={DishesTransactionReportView}
        exact
        layout={MainLayout}
        path="/dishesTransactionReport"
      />

      <RouteWithLayout
        component={SalesDetailsReportView}
        exact
        layout={MainLayout}
        path="/salesDetailsReport"
      />

      <RouteWithLayout
        component={OrderTypeReportView}
        exact
        layout={MainLayout}
        path="/orderTypeReport"
      />

      <RouteWithLayout
        component={MenuView}
        exact
        layout={MainLayout}
        path="/menu/:menuId?"
      />

      <RouteWithLayout
        component={MenuCategoryView}
        exact
        layout={MainLayout}
        path="/menuCategory/:menuId"
      />

      <RouteWithLayout
        component={CookiesCategoryListView}
        exact
        layout={MainLayout}
        path="/menuCookingCategoryList"
      />

      <RouteWithLayout
        component={UserView}
        exact
        layout={MainLayout}
        path="/user/:username?"
      />

      <RouteWithLayout
        component={UserView}
        exact
        layout={MainLayout}
        path="/userAdd/:restaurantId"
      />

      <RouteWithLayout
        component={RestaurantListView}
        exact
        layout={MainLayout}
        path="/restaurants"
      />

      <RouteWithLayout
        component={SubscriptionListView}
        exact
        layout={MainLayout}
        path="/subscriptionList"
      />

      <RouteWithLayout
        component={OrderListView}
        exact
        layout={MainLayout}
        path="/orders"
      />

      <RouteWithLayout
        component={TransactionListView}
        exact
        layout={MainLayout}
        path="/transactions"
      />

      <RouteWithLayout
        component={TimesheetListView}
        exact
        layout={MainLayout}
        path="/timesheets"
      />
      <RouteWithLayout
        component={TimesheetView}
        exact
        layout={MainLayout}
        path="/timesheet/add"
      />

      <RouteWithLayout
        component={StaffActivityListView}
        exact
        layout={MainLayout}
        path="/staffActivities"
      />

      <RouteWithLayout
        component={TransactionView}
        exact
        layout={MainLayout}
        path="/transaction/:transactionId?"
      />

      <RouteWithLayout
        component={VoucherListView}
        exact
        layout={MainLayout}
        path="/vouchers"
      />

      <RouteWithLayout
        component={NewVoucherView}
        exact
        layout={MainLayout}
        path="/newVoucher"
      />

      <RouteWithLayout
        component={PromotionListView}
        exact
        layout={MainLayout}
        path="/promotions"
      />

      <RouteWithLayout
        component={PromotionTypesView}
        exact
        layout={MainLayout}
        path="/promotionTypes"
      />

      <RouteWithLayout
        component={BuyNFreePromotionView}
        exact
        layout={MainLayout}
        path="/buyNFree/:promotionId?"
      />
      <RouteWithLayout
        component={BirthdayPromotionView}
        exact
        layout={MainLayout}
        path="/birthdayPromotion/:promotionId?"
      />

      <RouteWithLayout
        component={NewMemberPromotionView}
        exact
        layout={MainLayout}
        path="/newMemberPromotion/:promotionId?"
      />

      <RouteWithLayout
        component={TransactionView}
        exact
        layout={MainLayout}
        path="/promotions/:promotionId?"
      />

      <RouteWithLayout
        component={OrderView}
        exact
        layout={MainLayout}
        path="/orderDetail/:orderId?"
      />

      <RouteWithLayout
        component={RestaurantView}
        exact
        layout={MainLayout}
        path="/restaurant/:restaurantId?"
      />

      <RouteWithLayout
        component={RestaurantGroupView}
        exact
        layout={MainLayout}
        path="/restaurantGroup/:restaurantId?"
      />

      <RouteWithLayout
        component={MyRestaurantView}
        exact
        layout={MainLayout}
        path="/myrestaurant"
      />
      <RouteWithLayout
        component={GrantOfferListView}
        exact
        layout={MainLayout}
        path="/grantOffers"
      />

      <RouteWithLayout
        component={CapitalFaqsView}
        exact
        layout={MainLayout}
        path="/capitalFaq"
      />

      <RouteWithLayout
        component={OpenHoursView}
        exact
        layout={MainLayout}
        path="/openHours"
      />

      <RouteWithLayout
        component={RestaurantSettingsView}
        exact
        layout={MainLayout}
        path="/restaurantSettings"
      />

      <RouteWithLayout
        component={POSSettingsView}
        exact
        layout={MainLayout}
        path="/posSettings"
      />

      <RouteWithLayout
        component={AdyenSettingsView}
        exact
        layout={MainLayout}
        path="/adyenSettings"
      />

      <RouteWithLayout
        component={POSPrintSettingsView}
        exact
        layout={MainLayout}
        path="/posPrintSettings"
      />

      <RouteWithLayout
        component={POSPrinterSettingsView}
        exact
        layout={MainLayout}
        path="/posPrinterSettings"
      />

      <RouteWithLayout
        component={ServiceBellTypesView}
        exact
        layout={MainLayout}
        path="/serviceBellTypes"
      />

      <RouteWithLayout
        component={HolidaySurchargeView}
        exact
        layout={MainLayout}
        path="/surcharge/:surchargeId?"
      />

      <RouteWithLayout
        component={SurchargeListView}
        exact
        layout={MainLayout}
        path="/surchargeSettings"
      />

      <RouteWithLayout
        component={PaymentTypeListView}
        exact
        layout={MainLayout}
        path="/paymentTypes"
      />

      <RouteWithLayout
        component={PaymentTypeView}
        exact
        layout={MainLayout}
        path="/newPaymentType"
      />

      <RouteWithLayout
        component={QrCodesView}
        exact
        layout={MainLayout}
        path="/qrcodes/:restaurantId"
      />

      <RouteWithLayout
        component={BookingsView}
        exact
        layout={MainLayout}
        path="/bookings"
      />

      <RouteWithLayout
        component={BookingSessionsView}
        exact
        layout={MainLayout}
        path="/bookingSessions"
      />

      <RouteWithLayout
        component={BookingSettingsView}
        exact
        layout={MainLayout}
        path="/bookingSettings"
      />

      <RouteWithLayout
        component={BookingSessionView}
        exact
        layout={MainLayout}
        path="/bookingSession/:sessionId?"
      />

      <RouteWithLayout
        component={BookingSuccessView}
        exact
        layout={OnlineLayout}
        path="/bookingSuccess/:restaurantId"
      />

      <RouteWithLayout
        component={OBAdyenPaymentView}
        exact
        layout={OnlineLayout}
        path="/obPayment"
      />

      <RouteWithLayout
        component={OBPaymentSuccessView}
        exact
        layout={OnlineLayout}
        path="/obPaymentSuccess/:orderNumber"
      />

      <RouteWithLayout
        component={OBPaymentFailureView}
        exact
        layout={OnlineLayout}
        path="/obPaymentError/:orderNumber"
      />

      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />

      <RouteWithLayout
        component={SubscriptionsView}
        exact
        layout={MainLayout}
        path="/subscriptions"
      />
      {/* 
      <RouteWithLayout
        component={SubscribeView}
        exact
        layout={MainLayout}
        path="/subscribe"
      /> */}

      <RouteWithLayout
        component={PricesView}
        exact
        layout={MainLayout}
        path="/prices"
      />

      <RouteWithLayout
        component={DeviceListView}
        exact
        layout={MainLayout}
        path="/posDevices"
      />

      <RouteWithLayout
        component={POSClientListView}
        exact
        layout={MainLayout}
        path="/posClients"
      />

      <RouteWithLayout
        component={POSClientView}
        exact
        layout={MainLayout}
        path="/posClient"
      />

      <RouteWithLayout
        component={DisplayClientView}
        exact
        layout={MainLayout}
        path="/displayClient"
      />

      <RouteWithLayout
        component={DisplayClientListView}
        exact
        layout={MainLayout}
        path="/displayClients"
      />

      <RouteWithLayout
        component={KioskListView}
        exact
        layout={MainLayout}
        path="/kioskDevices"
      />

      <RouteWithLayout
        component={TerminalListView}
        exact
        layout={MainLayout}
        path="/terminalDevices"
      />

      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />

      <RouteWithLayout
        component={ResetPasswordView}
        exact
        layout={MinimalLayout}
        path="/forget-password"
      />

      <RouteWithLayout
        component={OnlineView}
        exact
        layout={OnlineLayout}
        path="/online/:menuId/:tableId"
      />

      <RouteWithLayout
        component={OnlineView}
        exact
        layout={OnlineLayout}
        path="/online/:menuId/:tableId/:orderId"
      />

      <RouteWithLayout
        component={RestaurantBookingView}
        exact
        layout={OnlineLayout}
        path="/restaurantBooking/:restaurantId"
      />

      <RouteWithLayout
        component={QueueFormView}
        exact
        layout={ExternalMinimalLayout}
        path="/queue/:restaurantId"
      />

      <RouteWithLayout
        component={DishDetailView}
        exact
        layout={OnlineLayout}
        path="/dishDetail"
      />

      <RouteWithLayout
        component={CartView}
        exact
        layout={OnlineLayout}
        path="/cart/:menuId/:tableId"
      />

      <RouteWithLayout
        component={CartView}
        exact
        layout={OnlineLayout}
        path="/cart/:menuId/:tableId"
      />

      <RouteWithLayout
        component={CheckoutView}
        exact
        layout={OnlineLayout}
        path="/checkout/:menuId/:tableId"
      />


      <RouteWithLayout
        component={H5WechatPayView}
        exact
        layout={OnlineLayout}
        path="/h5WechatPay"
      />

     <RouteWithLayout
        component={PaymentStatusView}
        exact
        layout={OnlineLayout}
        path="/wechatPayment/:restaurantId/:tableId/:orderNumber/:orderNo"
      />

      <RouteWithLayout
        component={AdyenPaymentView}
        exact
        layout={OnlineLayout}
        path="/adyenPayment/:menuId/:tableId/:reference"
      />

      <RouteWithLayout
        component={InvoiceView}
        exact
        layout={OnlineLayout}
        path="/invoice/:restaurantId/:orderId"
      />

      <RouteWithLayout
        component={AdyenPaymentResultView}
        exact
        layout={OnlineLayout}
        path="/paymentResult"
      />

      <RouteWithLayout
        component={OrderSuccessView}
        exact
        layout={OnlineLayout}
        path="/orderSuccess/:menuId/:tableId/:reference"
      />

      <RouteWithLayout
        component={OrderSuccessView}
        exact
        layout={OnlineLayout}
        path="/orderSuccess/:menuId/:tableId/:reference/:type"
      />

      <RouteWithLayout
        component={OrderSuccessDetailView}
        exact
        layout={OnlineLayout}
        path="/orderSuccessDetail/:menuId/:tableId/:reference"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
